<template>
  <v-app>
    <v-row justify="center" align="start">
      <v-col cols="11">
        <div class="jumbotron">
          <p class="display-1">Approve Batches</p>
        </div>
        <p class="lead">Claim Batches for Approval</p>
        <p class="font-weight-bold">List of saved batches</p>
        <hr />
        <List render="batch" :data="batches" :titles="titles" />
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
// Component module
import List from "@/views/apps/claim/ListView.vue";

export default {
  name: "batch-list",
  components: { List },
  data() {
    return {
      titles: [
        "Batch Name",
        "Date Recieved",
        "Provider",
        "Claims",
        "Total Claimed (NGN)",
        "Clearance Status",
        "",
      ],
    };
  },
  computed: {
    ...mapGetters({ batches: "claimModule/batches" }),
  },
};
</script>

<style scoped>
.position {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}
.bgColor {
  background-color: #eee;
}
</style>
